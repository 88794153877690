<template>
  <div class="survey" v-if="bigFiveResults.length === 0">
    <div class="container">
      <PrimePreloader v-if="loading"/>
      <div v-if="checkAccess === 0"><h1 class="text-center mt-3 mb-3">Время сдачи теста еще не настало!</h1></div>
      <div v-else>
        <h1 class="text-center mt-3 mb-3">Опросник Big Five</h1>
        <div class="table-block">
          <h5 class="mb-3">Ответьте на вопросы, касающиейся вашей личности, по шкале от <strong>7 (согласен)</strong> до
            <strong>1 (не согласен)</strong>.</h5>
          <table class="table">
            <thead>
            <tr>
              <th scope="col">№</th>
              <th scope="col">Я воспринимаю себя как</th>
              <th scope="col">7</th>
              <th scope="col">6</th>
              <th scope="col">5</th>
              <th scope="col">4</th>
              <th scope="col">3</th>
              <th scope="col">2</th>
              <th scope="col">1</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(question, index) in bigFiveQuestions" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ question.question }}</td>
              <td v-for="(score, idx) in bigFiveScore" :key="idx">
                <input @input="chooseScore(question.id, $event)" type="radio" :value="score.id" :name="index">
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <button class="btn btn-primary" @click="sendScore">Отправить</button>
      </div>
    </div>
  </div>
  <div v-else class="results">
    <div class="container">
      <div class="results-info text-center m-3">
        <h2>Результаты Big Five</h2>
      </div>
      <div class="results-chart">
        <Chart type="radar" :data="chartData" :options="chartOption"/>
      </div>
      <div class="results-table-block">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">Шкала</th>
            <th scope="col">Описание</th>
            <th scope="col">Сильные стороны</th>
            <th scope="col">Слабые стороны</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in bigFiveResults" :key="index">
            <td>{{ item.scale_id }}</td>
            <td>{{ item.title_ru }}</td>
            <td>{{ item.text_ru }}</td>
            <td>{{ item.strength_ru }}</td>
            <td>{{ item.weakness_ru }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "BigFive",
  data() {
    return {
      loading: true,
      buttonActive: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Результат',
            backgroundColor: 'rgba(13, 137, 236, 0.5)',
            borderColor: 'rgba(13, 137, 236, 0.5)',
            pointBackgroundColor: 'rgba(13, 137, 236, 0.5)',
            pointBorderColor: 'rgba(13, 137, 236, 0.5)',
            pointHoverBackgroundColor: 'rgba(13, 137, 236, 0.5)',
            pointHoverBorderColor: 'rgba(13, 137, 236, 0.5)',
            data: []
          }
        ]
      },
      chartOption: {
        events: [],
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          r: {
            min: 0,
            max: 14,
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('bigFive', ['GET_BIG_FIVE_QUESTIONS', 'GET_BIG_FIVE_SCORE', 'POST_BIG_FIVE_ANSWERS', 'GET_BIG_FIVE_RESULTS']),
    ...mapActions('checkAccess', ['GET_CHECK_ACCESS']),

    chooseScore(question_id, e) {
      let indexArray = this.buttonActive.findIndex(i => i.big_five_question_id === question_id)
      if (indexArray >= 0) {
        this.buttonActive.splice(indexArray, 1)
      }
      this.buttonActive.push({big_five_question_id: question_id, big_five_score_id: e.target.value})
    },

    async sendScore() {
      if (this.bigFiveQuestions.length !== this.buttonActive.length) {
        this.$error({title: "Заполните все данные!"})
      } else {
        if (await this.POST_BIG_FIVE_ANSWERS(this.buttonActive)) {
          await this.GET_BIG_FIVE_RESULTS();
          await this.getResults();
          this.$message({title: "Действие выполнено!"})
        }
      }
    },

    getResults() {
      let chartLabels = []
      let datasetsData = []
      for (let i = 0; i < this.bigFiveResults.length; i++) {
        chartLabels.push(this.bigFiveResults[i].scale_name.toUpperCase())
        datasetsData.push(this.bigFiveResults[i].score)
      }
      this.chartData.labels = chartLabels
      this.chartData.datasets[0].data = datasetsData
      console.log(this.chartData.datasets[0].data)
    }
  },
  computed: {
    ...mapState('bigFive', ['bigFiveQuestions', 'bigFiveScore', 'bigFiveResults']),
    ...mapState('checkAccess', ['checkAccess'])
  },
  async mounted() {
    await this.GET_CHECK_ACCESS('bigFive');
    await this.GET_BIG_FIVE_RESULTS();
    if (this.bigFiveResults.length === 0) {
      await this.GET_BIG_FIVE_QUESTIONS();
      await this.GET_BIG_FIVE_SCORE();
    } else {
      await this.getResults();
    }
    this.loading = false
  }
}

</script>

<style scoped>
.table-block, .results-table-block {
  overflow: auto;
}

.results-chart {
  width: 60%;
  height: 60%;
  margin: 0 auto;
}

.results-chart canvas {
  font-size: 16px;
}

.results-info {

}

@media screen and (min-width: 300px) and (max-width: 578px) {
  .results-chart {
    width: 100%;
    height: 100%;
  }
}
</style>
